.click {
  @apply absolute w-10 h-10 outline-none -right-5 -top-5;
  @apply z-10 flex items-center justify-center;
}

.close {
  @apply w-8 h-8 text-white rounded-full cursor-pointer bg-black-100;
  @apply flex items-center justify-center;

  & :global(.icon) {
    @apply fill-white w-[12px] h-auto;
  }
}

.content {
  @apply absolute bottom-auto right-auto bg-white top-1/2 left-1/2 max-w-1xl;
  @apply outline-none max-h-[95%];
  transform: translate(-50%, -50%);
  width: calc(100% - 32px);

  &:global(.is-square) {
    @apply max-w-3xl;

    & figure {
      @apply relative w-auto aspect-1;
    }
  }

  &:global(.is-landscape) {
    & :global(.image-wrapper) {
      @apply aspect-w-16 aspect-h-9;
    }
  }

  & :global(.content-wrapper) {
    @apply flex-grow p-6 md:p-8 sm:p-10;
    fit-content: fit-content;
  }

  & :global(.image-wrapper) {
    @apply relative flex-shrink-0 bg-black-100/10;
  }

  & :global(h2) {
    @apply pb-3;
  }

  & :global(.button) {
    @apply text-lg py-2.5 px-5 mx-auto font-serif font-bold;
    @apply inline-block mt-6;
  }
}

@screen md {
  .content {
    &:global(.is-square) {
      @apply flex items-center max-w-4xl;

      & figure {
        @apply relative w-80 aspect-1;
      }
    }
  }
}
